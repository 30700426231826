import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useMetadataUpload() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const singleMetadata = ref({})
  const isLoading = ref(false)
  const collection = 'metadata'

  const upload = async (data) => {
    isLoading.value = true

    try {
      const optionsToUpload = data.itemList?.value || []

      // Get metadata options
      const query = { _id: ObjectId(data.nameId.value) }
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')
  
      const actualMetadataOptions = item.options || []
      const newMetadataOptions = Array.from(new Set([...actualMetadataOptions, ...optionsToUpload]))
  
      // Update metadata options with new options added
      const payload = { options: newMetadataOptions }
      const action = { $set: payload }
  
      await updateItem({ collection, query, action })
  
      showSuccessMessage(i18n.t('message.metadata_uploaded'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.metadata_upload_error'))
    } finally {
      router.push({ name: 'organization-metadata-list' })
      isLoading.value = false
    }
  }

  return {
    upload,
    singleMetadata,
    isLoading
  }
}

<template lang="pug">
div
  Form(
    :data="singleMetadata",
    :metadataFieldDisabled="false",
    :isLoading="isLoading",
    :uploadMode="true"
    @submit="upload"
  )
</template>

<script>
import Form from '../shared/Form.vue'
import useMetadataUpload from './useMetadataUpload'

export default {
  components: {
    Form,
  },
  setup() {
    const { upload, singleMetadata, isLoading } = useMetadataUpload()

    return {
      singleMetadata,
      upload,
      isLoading,
    }
  },
}
</script>
